export default {
  MAIN_NAV: "MAIN_NAV",
  LANGUAGE_LOCALE: "LANGUAGE_LOCALE",
  SETTINGS: "SETTINGS",
  SHOW_ONBOARDING: "SHOW_ONBOARDING",
  LOGIN_TYPE: "LOGIN_TYPE",
  SSO_LOGOUT_URL: "SSO_LOGOUT_URL",
  ANNOUNCEMENTS: "ANNOUNCEMENTS",
  CHUNK_FAILED: "CHUNK_FAILED",
  PAYMENT_STATUS: "PAYMENT_STATUS",
  UPDATE_CARD_STATUS: "UPDATE_CARD_STATUS",
  EXTERNAL_SIGNIN_SIGNUP: "EXTERNAL_SIGNIN_SIGNUP",
  UPDATE_PASSWORD_TOKEN: "UPDATE_PASSWORD_TOKEN",
  PUBLIC_COURSE: "PUBLIC_COURSE",
  PENDING_CATALOG_ENROLLMENT: "PENDING_CATALOG_ENROLLMENT",
  LEARNER_NAVIGATION_TIP: "LEARNER_NAVIGATION_TIP",
  LEFT_TEST: "LEFT_TEST",
};
